import logo from "./logo.svg";
import "./App.css";
import { Data } from "./Data";

import react, { useEffect, useState } from "react";
import { DataCompact } from "./DataCompact";

function App() {
  let [password, setPassword] = useState("");
  let [access, setAccess] = useState(false);
  const [compactView, setCompactView] = useState(false);
  let [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const data2 = [1, 2];
  // useEffect(() => {
  //   console.log("useeffect");
  // }, [access]);
  const getAccess = (data) => {
    setData(data);
    setAccess(true);
    console.log(data);
  };

  const checkAccess = async (e) => {
    e.preventDefault();
    setIsLoading((old) => !old);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ pw: password }),
    };
    //http://127.0.0.1:5000/test   //https://test-server-0bqt.onrender.com/test //https://test-server-oregon.onrender.com
    try {
      const res = await fetch(
        "https://test-server-0bqt.onrender.com/test",
        requestOptions
      );
      const resJson = await res.json();
      console.log(resJson.body);
      if (resJson.body.access === "true") {
        getAccess(resJson.body.data);
      } else if (resJson.body.access === "false") {
        setIsLoading((old) => !old);
        alert("Wrong Password");
      }
    } catch (error) {
      setIsLoading((old) => !old);
      console.log(error.message);
      alert("If this issue persists contact the Admin");
    }

    //.then(data => this.setState({ postId: data.id }));

    //console.log(password);
  };
  return (
    <div className="container">
      {access ? (
        <div className="col-md-12 text-center mt-2">
          <button
            className="btn btn-primary"
            onClick={() => {
              setCompactView((value) => !value);
            }}
          >
            Compact View
          </button>
        </div>
      ) : (
        <div></div>
      )}
      {access ? (
        compactView ? (
          <div className="">
            <ul className="list-group-flush">
              {data.map((item) => {
                return (
                  <DataCompact
                    title={item.title}
                    url={item.url}
                    thumbnail={item.thumbnail}
                  ></DataCompact>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="row mx-auto mt-2">
            {data.map((item) => {
              return (
                <Data
                  title={item.title}
                  url={item.url}
                  thumbnail={item.thumbnail}
                ></Data>
              );
            })}
          </div>
        )
      ) : (
        <div className="container x">
          <div className="row justify-content-center">
            <div className="col-md-4 login-form">
              <form className="mt-5" onSubmit={checkAccess}>
                {isLoading && (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "25%",
                      }}
                      src="https://i.gifer.com/ZKZg.gif"
                    ></img>
                  </div>
                )}
                <h2 className="mb-4">Login</h2>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
