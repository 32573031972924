import React from "react";
import "./Data.css";
export const Data = (props) => {
  return (
    <React.Fragment>
      <div className="col-md-3 mt-3">
        <div className="card">
          <img
            src={props.thumbnail}
            //src="https://lipsum.app/640x480/"
            className="card-img-top"
            alt={props.url}
          ></img>
          <div className="card-body">
            <div className="d-grid col-12">
              <a href={props.url} target="_blank" className="card-link">
                <h5 className="card-title">{props.title}</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
