import "./DataCompact.css";
export const DataCompact = (props) => {
  return (
    <a href={props.url} className="link_deck" target="_blank">
      <li className="list-group-item">
        <span className="item-title">{props.title}</span>
        {/* <span>{props.description}</span> */}
      </li>
    </a>
  );
};
